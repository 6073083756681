function usDateStringToValue(usDateString: string) {
  const comps = usDateString
    .split("/")
    .map(parseFloat)
    .filter((c) => !Number.isNaN(c));
  if (comps.length !== 3) {
    return undefined;
  }

  const [monthNumber, date, year] = comps;
  // JS has difficulty with this - will use 1900s dates.
  // For ease don't support it.
  if (year < 100) {
    return undefined;
  }

  const monthIndex = monthNumber - 1;
  const proposed = new Date(year, monthIndex, date);

  // JS interface allows passing negative numbers and invalid
  // dates that auto-correct. We don't want to support these cases.
  if (
    proposed.getDate() !== date ||
    proposed.getMonth() !== monthIndex ||
    proposed.getFullYear() !== year
  ) {
    return undefined;
  }

  return proposed;
}

function valueToUsDateString(value: Date) {
  return [
    (value.getMonth() + 1).toString().padStart(2, "0"),
    value.getDate().toString().padStart(2, "0"),
    value.getFullYear().toString().padStart(4, "0"),
  ].join("/");
}

function dateToIso(date: Date) {
  return date.toISOString().split("T")[0];
}

function isoToUsDateString(value: string) {
  const comps = value
    .split("-")
    .map((c) => parseInt(c, 10))
    .filter((c) => !Number.isNaN(c));
  if (comps.length !== 3) {
    throw new Error(`Invalid iso date ${value}`);
  }

  const [year, month, date] = comps;
  return [
    month.toString().padStart(2, "0"),
    date.toString().padStart(2, "0"),
    year.toString().padStart(4, "0"),
  ].join("/");
}

export {
  dateToIso,
  usDateStringToValue,
  isoToUsDateString,
  valueToUsDateString,
};
