import FormValidityObserver, {
  type ValidationErrors,
} from "@form-observer/core/FormValidityObserver";
import type { ValidatableField } from "@form-observer/core/types";
import defaultFormErrors from "~/utils/form-handling/default-form-errors.ts";

class DcFormElement extends HTMLElement {
  private form: HTMLFormElement;
  private observer: FormValidityObserver;
  private isSubmitting: boolean;
  private submitHandler: ((event: SubmitEvent) => Promise<void>) | undefined;

  constructor() {
    super();

    this.isSubmitting = false;

    this.form = this.querySelector("form")!;
    this.form.setAttribute("novalidate", "");

    this.observer = new FormValidityObserver("submit", {
      defaultErrors: defaultFormErrors,
      revalidateOn: "input",
    });
    this.onSubmit = this.onSubmit.bind(this);
  }

  public registerSubmitHandler(handler: (event: SubmitEvent) => Promise<void>) {
    this.submitHandler = handler;
  }

  public configureFieldMessages(
    name: string,
    messages: ValidationErrors<string, ValidatableField, false>,
  ) {
    this.observer.configure(name, messages);
  }

  /* eslint-disable @typescript-eslint/no-misused-promises, @typescript-eslint/unbound-method */
  public connectedCallback() {
    this.form.addEventListener("submit", this.onSubmit);
    this.observer.observe(this.form);
  }

  public disconnectedCallback() {
    this.form.removeEventListener("submit", this.onSubmit);
    this.observer.unobserve(this.form);
  }
  /* eslint-enable @typescript-eslint/no-misused-promises, @typescript-eslint/unbound-method */

  private async onSubmit(event: SubmitEvent) {
    if (this.isSubmitting) {
      return;
    }

    const success = this.observer.validateFields({ focus: true });
    if (!success) {
      event.preventDefault();
      return;
    }

    // Custom js submit handler
    if (this.submitHandler) {
      event.preventDefault();
      this.isSubmitting = true;
      try {
        this.dispatchEvent(new CustomEvent("submitting"));
        await this.submitHandler(event);
        this.dispatchEvent(new CustomEvent("submitted"));
      } finally {
        this.isSubmitting = false;
      }
      return;
    }

    // Submit as ajax
    if (this.getAttribute("submit-as-ajax")) {
      event.preventDefault();
      this.isSubmitting = true;
      try {
        this.dispatchEvent(new CustomEvent("submitting"));

        const response = await fetch(this.form.action, {
          method: this.form.method,
          headers: {
            Accept: "application/json",
          },
          body: new FormData(this.form),
        });
        const result = await response.json();

        this.dispatchEvent(new CustomEvent("submitted", { detail: result }));
      } finally {
        this.isSubmitting = false;
      }
      return;
    }

    // Dialog submit
    if (this.form.method === "dialog") {
      this.dispatchEvent(
        new CustomEvent("submitted", { detail: new FormData(this.form) }),
      );
      return;
    }

    // Native browser submit
    this.isSubmitting = true;
    this.dispatchEvent(new CustomEvent("submitting"));
  }
}

const formElementName = "dc-form";

export { formElementName };
export default DcFormElement;
