import type { ValidatableField, ValidationErrors } from "@form-observer/core";
import { isoToUsDateString } from "../date.ts";

const defaultFormErrors = {
  required: "Required",
  pattern: "Invalid",
  min(field: ValidatableField) {
    if (
      "min" in field &&
      (typeof field.min === "string" || typeof field.min === "number")
    ) {
      if (field.type === "date") {
        return `Must be after ${field.min}`;
      }
      return `Must be at least ${field.min}`;
    }
    return "Too small";
  },
  max(field: ValidatableField) {
    if (
      "max" in field &&
      (typeof field.max === "string" || typeof field.max === "number")
    ) {
      if (field.type === "date") {
        return `Must be before ${isoToUsDateString(field.max.toString())}`;
      }
      return `Must be at most ${field.max}`;
    }
    return "Too large";
  },
  minlength(field: ValidatableField) {
    if (
      "minlength" in field &&
      (typeof field.minlength === "string" ||
        typeof field.minlength === "number")
    ) {
      return `Must be at least ${field.minlength} characters`;
    }
    return "Too short";
  },
  maxlength(field: ValidatableField) {
    if (
      "maxlength" in field &&
      (typeof field.maxlength === "string" ||
        typeof field.maxlength === "number")
    ) {
      return `Must be at most ${field.maxlength} characters`;
    }
    return "Too long";
  },
  type(field: ValidatableField) {
    if ("type" in field && typeof field.type === "string") {
      return `Invalid ${field.type}`;
    }
    return "Invalid";
  },
} satisfies ValidationErrors<string, ValidatableField, false>;

export default defaultFormErrors;
